import { Stack } from '@mui/material';
import type {
  InventoryResource,
  Snapshot,
  SnapshotPropertiesVolumePropertiesInner,
} from '@repo/api-gw-sdk';
import { useState } from 'react';

import type { BackupVault } from '@/data/vaults/backupVault';

import { SnapshotRow } from './snapshotRow';

export type RestoreActionType = 'snapshot' | 'volume' | 'full-instance';

export const Ec2SnapshotRow = ({
  snap,
  vault,
  onRestore,
  isSelected,
  onSelect,
  resource,
}: {
  snap: Snapshot;
  vault: BackupVault | undefined;
  onRestore: (
    vol: SnapshotPropertiesVolumePropertiesInner,
    actionType: RestoreActionType
  ) => void;
  isSelected?: boolean;
  onSelect: () => void;
  resource: InventoryResource;
}) => {
  const [selectedVolume, setSelectedVolume] = useState<string>(
    snap.properties?.volumeProperties?.[0].volumeId || ''
  );

  if (!snap.properties?.volumeProperties) {
    return null;
  }

  const numOfVols = snap.properties.volumeProperties.length || 0;

  return (
    <Stack key={snap.id} className='w-full'>
      {snap.properties.volumeProperties.map((vol) => (
        <SnapshotRow
          resource={resource}
          key={`${snap.id}_${vol.volumeId}`}
          isSelectable={numOfVols > 1}
          isSelected={isSelected && selectedVolume === vol.volumeId}
          onSelect={() => {
            setSelectedVolume(vol.volumeId);
            onSelect();
          }}
          snap={snap}
          vault={vault}
          volume={vol.volumeName}
          onRestore={() => onRestore(vol, 'volume')}
          onConvertSnapshot={() => onRestore(vol, 'snapshot')}
        />
      ))}
    </Stack>
  );
};
