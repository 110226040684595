import { Box, Button, Checkbox, Stack, Typography } from '@mui/material';
import type {
  AzureOnboardingStatus,
  AzureSubscription,
} from '@repo/api-gw-sdk';
import { useRef, useState } from 'react';

import { useDAL } from '@/data/dal';

import type { AccountType } from './useCreateAccount';

export interface CreateAzureAccountPanelProps {
  accountType: AccountType;
  onCreated: () => void;
  setLoading: (loading: boolean) => void;
  loading: boolean;
}

export default function CreateAzureAccountPanel(
  props: CreateAzureAccountPanelProps
) {
  const dal = useDAL();
  const requestId = useRef<string>(crypto.randomUUID());
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [subscriptions, setSubscriptions] = useState<
    AzureSubscription[] | null
  >();
  const [selectedSubscriptions, setSelectedSubscriptions] = useState<
    AzureSubscription[]
  >([]);
  const [onboardingStatus, setOnboardingStatus] = useState<
    AzureOnboardingStatus[] | null
  >(null);
  const [state, setState] = useState<'initial' | 'pending' | 'completed'>(
    'initial'
  );
  const openConsentDialog = () => {
    const width = 600,
      height = 800;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    props.setLoading(true);
    setErrorMessage(null);
    setSubscriptions(null);
    setSelectedSubscriptions([]);
    setOnboardingStatus(null);
    void dal.azure.getConsentUrl(requestId.current).then((resp) => {
      if (resp.error) {
        props.setLoading(false);
        setErrorMessage(resp.error);
        return;
      }

      const authWindow = window.open(
        resp.url,
        'authWindow',
        `width=${width},height=${height},top=${top},left=${left}`
      );

      // Monitor the popup window
      const interval = setInterval(() => {
        try {
          if (!authWindow) {
            clearInterval(interval);
            props.setLoading(false);
            return;
          }

          // Close if user closes the window
          if (authWindow.closed) {
            clearInterval(interval);
            void dal.azure
              .listSubscriptions(requestId.current)
              .then((resp) => {
                setSubscriptions(resp.subscriptions);
              })
              .catch(() => {
                props.setLoading(false);
                setErrorMessage('Operation failed');
                setSubscriptions(null);
              });
          }
        } catch {
          // Ignore cross-origin errors
        }
      }, 500);
    });
  };

  const onboardSubscriptions = () => {
    setState('pending');
    setSubscriptions(selectedSubscriptions);
    void dal.azure
      .onboardSubscriptions(
        requestId.current,
        props.accountType,
        selectedSubscriptions
      )
      .then(() => {
        const checkOnboardingStatus = async () => {
          if (onboardingInProgress) return; // Prevent overlapping calls
          onboardingInProgress = true;

          try {
            const resp = await dal.azure.getOnboardingStatus(requestId.current);
            setOnboardingStatus(resp.subscriptions);

            const allCompleted = resp.subscriptions.every(
              (sub) => sub.status !== 'Accepted' && sub.status !== 'Running'
            );

            if (allCompleted) {
              setState('completed');
            } else {
              setTimeout(() => {
                void checkOnboardingStatus();
              }, 2000); // Retry after 2 seconds
            }
          } catch {
            setState('completed');
            setSubscriptions(null);
            setErrorMessage('Failed to onboard subscriptions');
          } finally {
            onboardingInProgress = false; // Reset the flag after each call
          }
        };

        let onboardingInProgress = false;
        void checkOnboardingStatus();
      })
      .catch(() => {
        setState('completed');
        setErrorMessage('Failed to onboard subscriptions');
      });
  };

  return (
    <Box className='px-[20px]'>
      {!subscriptions && (
        <>
          <Box className='my-[15px]'>
            Before we begin,{' '}
            <b>
              Please ensure you have sufficient permissions to create a service
              principal in your Azure subscriptions.
            </b>
          </Box>
          <Box className='my-[15px]'>
            We&apos;ll take you straight to the Azure login form to set
            Eon&apos;s permissions. Everything is ready for you, just follow the
            steps, confirm, and get back to us.
          </Box>
          <Box className='flex flex-col justify-center my-[30px] gap-[8px]'>
            <Button
              variant='contained'
              disabled={props.loading}
              data-testid='azure-login-btn'
              onClick={() => openConsentDialog()}
            >
              {props.loading && (
                <i className='line-md-loading-loop w-[20px] h-[20px] mr-[8px]' />
              )}
              Open Microsoft Azure{' '}
              <i className='material-symbols-open-in-new w-[20px] h-[20px] ml-[8px]' />
            </Button>
          </Box>
        </>
      )}
      {subscriptions && (
        <>
          {state !== 'completed' && (
            <>
              <Box className='my-[15px]'>
                <Box className='my-[15px]'>Select a subscriptions:</Box>
                <Box className='my-[15px]'>
                  {subscriptions.map((sub) => (
                    <Stack
                      direction='row'
                      key={sub.id}
                      alignItems='center'
                      gap='4px'
                    >
                      <Checkbox
                        disabled={state !== 'initial'}
                        checked={selectedSubscriptions.some(
                          (selectedSub) => selectedSub.id === sub.id
                        )}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setSelectedSubscriptions([
                              ...selectedSubscriptions,
                              sub,
                            ]);
                          } else {
                            setSelectedSubscriptions(
                              selectedSubscriptions.filter(
                                (selectedSub) => selectedSub.id !== sub.id
                              )
                            );
                          }
                        }}
                      />
                      <Typography>{sub.name}</Typography>
                    </Stack>
                  ))}
                </Box>
              </Box>
              <Box className='flex flex-col justify-center my-[30px] gap-[8px]'>
                <Button
                  variant='contained'
                  disabled={
                    state !== 'initial' || selectedSubscriptions.length === 0
                  }
                  onClick={() => onboardSubscriptions()}
                >
                  {state === 'pending' && (
                    <i className='line-md-loading-loop w-[20px] h-[20px] mr-[8px]' />
                  )}
                  Onboard Selected Subscriptions
                </Button>
              </Box>
            </>
          )}
          {state === 'completed' && (
            <Box className='my-[15px]'>
              <Box className='my-[15px]'>
                <b>Operation completed.</b>
              </Box>
              <Box className='my-[15px]'>
                {onboardingStatus?.map((sub) => (
                  <Box key={sub.subscriptionId}>
                    <Box>
                      {
                        subscriptions.find(
                          (s) => s.subscriptionId === sub.subscriptionId
                        )?.name
                      }{' '}
                      - {sub.status}
                    </Box>
                  </Box>
                ))}
              </Box>
              <Box className='flex flex-col justify-center my-[30px] gap-[8px]'>
                <Button variant='contained' onClick={props.onCreated}>
                  Close
                </Button>
              </Box>
            </Box>
          )}
        </>
      )}
      {errorMessage && (
        <Typography variant='body1' color='error' data-testid='error-message'>
          <Stack className='my-[15px]' direction='row' alignItems='center'>
            <i className='material-symbols-error-outline-rounded mr-[8px]'></i>
            {errorMessage}
          </Stack>
        </Typography>
      )}
    </Box>
  );
}
