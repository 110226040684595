import { Box, CardContent, Divider } from '@mui/material';
import { useEffect } from 'react';

import { EncryptionComponent } from '@/components/encryption/encryptionComponent';
import { AvailabilityZoneSelect } from '@/components/regions/availabilityZoneSelect';
import { RegionSelect } from '@/components/regions/regionSelect';
import { TagsSection } from '@/components/tags/tagsSection';
import { VolumeSettingsSection } from '@/components/volumes/volumeSettings';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';

import { EonTagRemark } from './shared';

import { StepContainer } from '../../wizard/StepContainer';
import { type RestoreVolumeState } from '../restoreVolumeWizard';

export const SnapshotConfiguration = (props: StepProps<RestoreVolumeState>) => {
  const dal = useDAL();
  const { body: snapshotResponse } = dal.inventory.snapshots.get(
    props.wizardState.snapshotId!
  );

  const volumeProperties =
    snapshotResponse?.snapshot.properties?.volumeProperties?.find(
      (x) => x.volumeName === props.wizardState.providerVolumeId
    );

  useEffect(() => {
    if (volumeProperties) {
      props.setWizardState((state) => ({
        ...state,
        tags: volumeProperties.tags || {},
        regionName: volumeProperties.region,
        availabilityZone: volumeProperties.availabilityZone,
        volumeSettings: volumeProperties.volumeSettings,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snapshotResponse]);

  return (
    <StepContainer
      navigationComponent={<EonTagRemark />}
      stepperLabels={props.stepperLabels}
      stepperIndex={props.stepperLabels.length - 1}
      nextButtonText='Start'
      onBackClick={props.back}
      canGoNext={() => {
        if (!props.wizardState.regionName) {
          return false;
        }

        if (
          props.wizardState.isEncrypted &&
          !props.wizardState.encryptionKeyId
        ) {
          return false;
        }

        if (
          props.wizardState.actionType === 'volume' &&
          !props.wizardState.availabilityZone
        ) {
          return false;
        }

        return true;
      }}
      onNextClick={() => {
        const tags = props.wizardState.tags || {};
        tags['eon:restore'] = 'true';

        if (props.wizardState.actionType === 'snapshot') {
          void dal.convert
            .volume(
              props.wizardState.resourceIdForSnapshots,
              props.wizardState.snapshotId!,
              {
                providerVolumeId: props.wizardState.providerVolumeId,
                tags,
                regionName: props.wizardState.regionName ?? '',
                restoreAccountId: props.wizardState.restoreAccountId!,
                encryptionKeyId: props.wizardState.encryptionKeyId,
                snapshotEncryptionKeyId: props.wizardState.isEncrypted
                  ? props.wizardState.encryptionKeyId
                  : undefined,
              }
            )
            .then(() => {
              props.abort();
            });
        } else if (props.wizardState.actionType === 'volume') {
          void dal.restore
            .volume(
              props.wizardState.resourceIdForSnapshots,
              props.wizardState.snapshotId!,
              {
                providerVolumeId: props.wizardState.providerVolumeId,
                tags,
                restoreAccountId: props.wizardState.restoreAccountId!,
                encryptionKeyId: props.wizardState.encryptionKeyId,
                availabilityZone: props.wizardState.availabilityZone!,
                volumeSettings: props.wizardState.volumeSettings!,
                volumeEncryptionKeyId: props.wizardState.isEncrypted
                  ? props.wizardState.encryptionKeyId
                  : undefined,
              }
            )
            .then(() => {
              props.abort();
              props.onSuccess('Restore job has started!');
            });
        }
      }}
    >
      <Box className='mb-[24px]'>
        <Box className='w-full flex justify-between mt-[20px] gap-[36px]'>
          <RegionSelect
            accountId={props.wizardState.restoreAccountId}
            regionName={props.wizardState.regionName}
            onChange={(region) =>
              props.setWizardState((state) => ({
                ...state,
                regionName: region,
                availabilityZone: undefined,
                encryptionKeyId: undefined,
              }))
            }
          />
          {props.wizardState.actionType === 'volume' && (
            <AvailabilityZoneSelect
              accountId={props.wizardState.restoreAccountId}
              regionName={props.wizardState.regionName}
              availabilityZone={props.wizardState.availabilityZone}
              onChange={(availabilityZone) =>
                props.setWizardState((state) => ({
                  ...state,
                  availabilityZone,
                }))
              }
            />
          )}
        </Box>
      </Box>
      {props.wizardState.actionType === 'volume' && (
        <>
          <Divider />
          <CardContent className='px-0'>
            <VolumeSettingsSection
              initialSettings={volumeProperties?.volumeSettings}
              settings={props.wizardState.volumeSettings}
              onChange={(volumeSettings) =>
                props.setWizardState((state) => ({ ...state, volumeSettings }))
              }
            />
          </CardContent>
        </>
      )}
      <Divider />
      <CardContent className='px-0'>
        {props.wizardState.regionName && (
          <EncryptionComponent
            isEncrypted={props.wizardState.isEncrypted}
            encryptionKeyId={props.wizardState.encryptionKeyId}
            accountId={props.wizardState.restoreAccountId!}
            regionName={props.wizardState.regionName}
            onChange={(encryption) => {
              if (encryption.isEncrypted) {
                props.setWizardState((state) => ({
                  ...state,
                  isEncrypted: true,
                  encryptionKeyId:
                    encryption.mode === 'arn'
                      ? encryption.arn
                      : encryption.encryptionKeyId,
                }));
              } else {
                props.setWizardState((state) => ({
                  ...state,
                  encryptionKeyId: undefined,
                  isEncrypted: false,
                }));
              }
            }}
          />
        )}
      </CardContent>
      <Divider />
      <CardContent className='px-0'>
        <TagsSection
          initialTags={volumeProperties?.tags || {}}
          tags={props.wizardState.tags || {}}
          onChange={(tags) =>
            props.setWizardState((state) => ({ ...state, tags }))
          }
        />
      </CardContent>
    </StepContainer>
  );
};
