import type { InventoryResource } from '@repo/api-gw-sdk';
import { BackupStatus, ResourceType } from '@repo/api-gw-sdk';
import { useState } from 'react';

import { useFeatureFlags } from '@/contexts/useFeatureFlags';
import { useRoles } from '@/contexts/useRoles';
import { useRouting } from '@/contexts/useRouting';
import { useDAL } from '@/data/dal';
import {
  InventoryResourceActionsKeys,
  type Action,
} from '@/data/inventory/actions';
import useBackupVaults from '@/data/vaults/useBackupVaults';

const useInstanceActions = (
  resource: InventoryResource | undefined,
  onEntityChange: () => Promise<void>,
  setSnackbarOpen: (isOpen: boolean) => void,
  setAssignBackupPolicyDialogOpen: (isOpen: boolean) => void
) => {
  const { assignBackupPolicyToSingleResource } = useFeatureFlags();
  const [isBackupInProgress, setIsBackupInProgress] = useState(false);
  const dal = useDAL();
  const { isAuthorized, isAuthorizedResource } = useRoles();
  const routing = useRouting();
  const vaults = useBackupVaults();

  const triggerTakeSnapshot = (resource: InventoryResource) => {
    if (isBackupInProgress) {
      console.error('backup already in progress');
      return;
    }
    setIsBackupInProgress(true);

    if (vaults.loading) {
      console.error('vaults are still loading');
      return;
    }

    if (!vaults.data?.length) {
      console.error('no vaults found');
      return;
    }

    const vaultId = vaults.data[0].id;

    setSnackbarOpen(true);

    void dal.jobs.backup
      .create(resource.id, vaultId, 7)
      .finally(() => setIsBackupInProgress(false));
  };

  const takeSnapshotAction: Action<InventoryResource> = {
    key: InventoryResourceActionsKeys.TakeSnapshot,
    tooltip: 'Take Snapshot',
    icon: 'material-symbols-backup-outline-rounded',
    disabled: () =>
      !isAuthorizedResource('create:jobs', resource) ||
      isBackupInProgress ||
      resource?.backupStatus === BackupStatus.Terminated ||
      resource?.backupStatus === BackupStatus.Disconnected,
    execute: (resource: InventoryResource) => {
      triggerTakeSnapshot(resource);
    },
    predicate: () =>
      resource?.resourceType === ResourceType.Ec2 ||
      resource?.resourceType === ResourceType.Rds ||
      resource?.resourceType === ResourceType.S3 ||
      resource?.resourceType === ResourceType.AtlasCluster,
  };

  const triggerExcludeBackup = async (resource: InventoryResource) => {
    await dal.inventory.resources
      .excludeResourceFromBackup(resource.id)
      .then(() => onEntityChange());
  };

  const triggerAllowBackups = async (resource: InventoryResource) => {
    await dal.inventory.resources
      .cancelResourceBackupExclusion(resource.id)
      .then(() => onEntityChange());
  };

  const allowBackupsAction: Action<InventoryResource> = {
    key: InventoryResourceActionsKeys.AllowBackups,
    disabled: () => !isAuthorizedResource('update:exclude_resource', resource),
    title: 'Cancel Backup Exclusion',
    tooltip: 'Cancel Backup Exclusion',
    icon: 'material-symbols-add-circle-outline',
    execute: (resource: InventoryResource) => {
      void triggerAllowBackups(resource);
    },
    predicate: () => resource?.backupStatus === BackupStatus.ExcludedFromBackup,
  };

  const excludeFromBackupAction: Action<InventoryResource> = {
    key: InventoryResourceActionsKeys.ExcludeFromBackup,
    disabled: () => !isAuthorizedResource('update:exclude_resource', resource),
    title: 'Exclude from Backup',
    tooltip: 'Exclude from Backup',
    icon: 'material-symbols-hide-source-outline-rounded',
    execute: (resource: InventoryResource) => {
      void triggerExcludeBackup(resource);
    },
    predicate: () => resource?.backupStatus !== BackupStatus.ExcludedFromBackup,
  };

  const triggerExploreDB = (resource: InventoryResource) => {
    routing.push(`/db/${resource.id}`);
  };

  const exploreDBAction: Action<InventoryResource> = {
    key: InventoryResourceActionsKeys.Explore,
    title: 'Database Explorer',
    tooltip: 'Database Explorer',
    icon: 'material-symbols-frame-inspect-rounded',
    disabled: () => !isAuthorizedResource('read:db_explorer', resource),
    execute: (resource: InventoryResource) => {
      triggerExploreDB(resource);
    },
    predicate: () =>
      resource?.resourceType === ResourceType.Rds ||
      resource?.resourceType === ResourceType.AtlasCluster ||
      (resource?.resourceType === ResourceType.Ec2 &&
        !!resource?.classifications?.appsDetails?.apps?.find(
          (app) => app.paths?.length
        )),
  };

  const triggerExploreFiles = (resource: InventoryResource) => {
    routing.push(`/explorer?resourceId=${resource.id}&path=`);
  };

  const exploreFilesAction: Action<InventoryResource> = {
    key: InventoryResourceActionsKeys.Explore,
    title: 'File Explorer',
    tooltip: 'File Explorer',
    icon: 'material-symbols-frame-inspect-rounded',
    disabled: () => !isAuthorizedResource('read:file_explorer', resource),
    execute: (resource: InventoryResource) => {
      triggerExploreFiles(resource);
    },
    predicate: () =>
      resource?.resourceType === ResourceType.Ec2 ||
      resource?.resourceType === ResourceType.S3,
  };

  const assignToBackupPolicyAction: Action<InventoryResource> = {
    key: InventoryResourceActionsKeys.AssignToBackupPolicy,
    title: 'Assign to backup policy',
    tooltip: 'Assign to backup policy',
    icon: 'material-symbols-bookmark-add-outline',
    disabled: () => !isAuthorized('update:policies'),
    execute: () => {
      setAssignBackupPolicyDialogOpen(true);
    },
    predicate: () => assignBackupPolicyToSingleResource,
  };

  const actions: Action<InventoryResource>[] = [
    assignToBackupPolicyAction,
    exploreFilesAction,
    exploreDBAction,
    takeSnapshotAction,
    excludeFromBackupAction,
    allowBackupsAction,
  ].filter((action) => (action.predicate ? action.predicate() : true));

  return actions;
};

export default useInstanceActions;
