import {
  Box,
  Button,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';

import { TransactionalTextField } from '@/components/shared/transactionalTextField';

import { Tag } from '../tag';

export const TagsSection = ({
  initialTags,
  tags,
  onChange,
}: {
  tags: Record<string, string>;
  initialTags: Record<string, string>;
  onChange: (tags: Record<string, string>) => void;
}) => {
  const [keepOriginalTags, setKeepOriginalTags] = useState<boolean>(true);

  return (
    <Box className='w-full'>
      <Box className='flex justify-between items-center'>
        <Typography component={'span'} variant='h6'>
          Keep Original Tags
        </Typography>
        <Switch
          size='small'
          checked={keepOriginalTags}
          onChange={() => {
            setKeepOriginalTags(!keepOriginalTags);
            if (!keepOriginalTags) {
              onChange(initialTags);
            }
          }}
        />
      </Box>
      {keepOriginalTags && (
        <Stack className='mt-[20px] w-full' gap='8px' direction='row'>
          {Object.entries(tags).map((entry) => (
            <Tag
              variant='outlined'
              key={entry[0]}
              content={entry.filter((x) => x).join('=')}
            />
          ))}
        </Stack>
      )}
      {!keepOriginalTags && (
        <Box className='mt-[20px] w-1/2'>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell className='border-0 pl-0'>Key</TableCell>
                <TableCell className='border-0 pl-0'>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(tags).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell className='border-0 pl-0'>
                    <TransactionalTextField
                      initValue={key}
                      onChange={(newKey) => {
                        if (key !== newKey) {
                          const newState = {
                            ...tags,
                            [newKey]: tags[key],
                          };

                          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                          delete newState[key];
                          onChange(newState);
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell className='border-0 pl-0'>
                    <TextField
                      tabIndex={2}
                      size='small'
                      value={value}
                      onChange={(event) => {
                        const newState = {
                          ...tags,
                          [key]: event.target.value,
                        };
                        onChange(newState);
                      }}
                    />
                  </TableCell>
                  <TableCell className='border-0 pl-0'>
                    <i
                      className={
                        'material-symbols-close-rounded cursor-pointer table-cell'
                      }
                      onClick={() => {
                        const newState = {
                          ...tags,
                        };

                        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                        delete newState[key];
                        onChange(newState);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button
            className='mt-[20px]'
            size={'small'}
            variant='outlined'
            onClick={() => onChange({ ...tags, '': '' })}
          >
            + Add Tag
          </Button>
        </Box>
      )}
    </Box>
  );
};
