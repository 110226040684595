import { Button, Divider, Stack, Typography } from '@mui/material';
import type { DataAccessRule, Role } from '@repo/api-gw-sdk';
import { useEffect, useState } from 'react';

import { StepContainer } from '@/components/wizard/StepContainer';
import type { StepProps } from '@/components/wizard/StepProps';
import type { PermissionDescriptor } from '@/contexts/rolesContext';
import { useRoles } from '@/contexts/useRoles';

import { CustomRoleConditionEditorDialog } from './customRoleConditionEditorDialog';
import { DataAccessRuleComponent } from './dataAccessRuleTooltip';
import { RolePermissions } from './rolePermissions';
import type { UpsertCustomRoleState } from './upsertCustomRoleFlow';

import { Accordion, AccordionDetails, AccordionSummary } from '../accordion';
import { Icon } from '../shared/icon';

export const CustomRoleScopesSelection = (
  props: StepProps<UpsertCustomRoleState>
) => {
  const { permissions, isAuthorized } = useRoles();
  const [permissionsList, setPermissionsList] = useState<string[]>(
    Object.keys(permissions).filter((x) =>
      permissions[x].scopes.every((x) => isAuthorized(x, props.wizardState))
    )
  );

  const [conditionDialogOptions, setConditionDialogOptions] = useState<{
    isOpen: boolean;
    permission?: PermissionDescriptor;
    dataAccessRule?: DataAccessRule;
  }>({
    isOpen: false,
  });

  useEffect(() => {
    const scopes = permissionsList.reduce<string[]>((agg, permission) => {
      permissions[permission].scopes.forEach((scope) => {
        if (!agg.includes(scope)) {
          agg.push(scope);
        }
      });
      return agg;
    }, []);
    props.setWizardState((state) => ({ ...state, scopes }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionsList]);

  return (
    <StepContainer
      hideStepper
      onBackClick={props.back}
      canGoNext={() =>
        props.wizardState.scopes.length > 0 ||
        Object.values(props.wizardState.dataAccessRules || {}).some(
          (x) => x.scopes.length > 0
        )
      }
      nextButtonText={props.wizardState.id ? 'Save Changes' : 'Create Role'}
      onNextClick={() => {
        props.finish();
      }}
    >
      <Stack className='flex-grow'>
        <Accordion className='mt-[-24px] px-[52px]'>
          <AccordionSummary
            sx={{
              '.Mui-expanded': {
                '.conditions-count': {
                  display: 'none',
                },
              },
            }}
          >
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              flexGrow={1}
            >
              <Typography>Data access conditions</Typography>
              <Stack
                direction='row'
                alignItems='center'
                gap='4px'
                className='conditions-count'
              >
                <Icon iconClass='material-symbols-rule-rounded' />
                <Typography
                  sx={{ fontWeight: '400 !important' }}
                >{`${Object.keys(props.wizardState.dataAccessRules || {}).length} conditions`}</Typography>
              </Stack>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            {Object.keys(props.wizardState.dataAccessRules || {}).length ===
              0 && (
              <Stack alignItems='center' gap='24px' className='py-[52px]'>
                <Typography variant='h2' sx={{ fontWeight: '600 !important' }}>
                  Create data access condition
                </Typography>
                <Typography
                  className='max-w-[580px]'
                  textAlign='center'
                  lineHeight='20px'
                >
                  Apply conditions on the selected permissions for this role to
                  define which resources the user can’t access based on resource
                  tags, data classes, and other properties.
                </Typography>
                <Button
                  variant='outlined'
                  onClick={() =>
                    setConditionDialogOptions({
                      isOpen: true,
                      dataAccessRule: undefined,
                      permission: undefined,
                    })
                  }
                >
                  <i className='material-symbols-rule-rounded text-xl' />
                  <Typography className='font-semibold ml-[12px]'>
                    Create Condition
                  </Typography>
                </Button>
              </Stack>
            )}
            {Object.keys(props.wizardState.dataAccessRules || {}).length !==
              0 && (
              <Stack>
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  flexGrow={1}
                >
                  <Typography>
                    {`${Object.keys(props.wizardState.dataAccessRules || {}).length} conditions are available. They can be applied on permissions
                  to control access.`}
                  </Typography>
                  <Button
                    onClick={() =>
                      setConditionDialogOptions({
                        isOpen: true,
                        dataAccessRule: undefined,
                        permission: undefined,
                      })
                    }
                  >
                    <i className='material-symbols-add-rounded text-xl' />
                    <Typography className='font-semibold ml-[12px]'>
                      Add Condition
                    </Typography>
                  </Button>
                </Stack>
                <Stack
                  direction='row'
                  gap='24px'
                  className='mt-[24px]'
                  flexWrap='wrap'
                >
                  {Object.values(props.wizardState.dataAccessRules || {}).map(
                    (rule) => (
                      <DataAccessRuleComponent
                        key={rule.name}
                        showActions={true}
                        dataAccessRule={rule}
                        onEdit={() =>
                          setConditionDialogOptions({
                            isOpen: true,
                            dataAccessRule: rule,
                            permission: undefined,
                          })
                        }
                        onDelete={() =>
                          props.setWizardState((state) => ({
                            ...state,
                            dataAccessRules: Object.fromEntries(
                              Object.entries(
                                state.dataAccessRules || {}
                              ).filter((entry) => entry[0] !== rule.name)
                            ),
                          }))
                        }
                      />
                    )
                  )}
                </Stack>
              </Stack>
            )}
          </AccordionDetails>
        </Accordion>
        <Divider sx={{ mx: '-40px', mt: '16px', mb: '40px' }} />
        <Stack
          className='px-[60px] flex-grow'
          gap='24px'
          color={'var(--mui-palette-text-primary)'}
        >
          <Typography>{`Choose permissions to give users with this role:`}</Typography>
          <RolePermissions
            role={props.wizardState}
            editMode
            onChange={(permissionName, value) => {
              const dataAccessRules = clearPermissionFromDataAccessRules(
                permissions[permissionName],
                props.wizardState.dataAccessRules
              );

              props.setWizardState((state) => ({
                ...state,
                dataAccessRules,
              }));

              setPermissionsList((state) =>
                value
                  ? [...state, permissionName]
                  : state.filter((x) => x !== permissionName)
              );
            }}
            onConditional={(permissionName, dataAccessRule) => {
              const permission = permissions[permissionName];
              if (dataAccessRule) {
                props.setWizardState((state) => ({
                  ...state,
                  dataAccessRules: Object.entries(
                    state.dataAccessRules || {}
                  ).reduce<Role['dataAccessRules']>((agg, [key, val]) => {
                    const permissionScopes =
                      permission.conditionalScopesOverrides ||
                      permission.scopes;

                    const updatedScopes =
                      key === dataAccessRule.name
                        ? [
                            ...new Set([
                              ...(val.scopes || []),
                              ...permissionScopes,
                            ]),
                          ]
                        : val.scopes.filter(
                            (x) => !permissionScopes.includes(x)
                          );

                    agg![key] = {
                      ...val,
                      scopes: updatedScopes,
                    };

                    return agg;
                  }, {}),
                }));
              } else {
                setConditionDialogOptions({
                  isOpen: true,
                  permission,
                  dataAccessRule,
                });
              }
            }}
          />
        </Stack>
      </Stack>
      <CustomRoleConditionEditorDialog
        open={conditionDialogOptions.isOpen}
        dataAccessRule={conditionDialogOptions.dataAccessRule}
        dataAccessRules={props.wizardState.dataAccessRules}
        onCancel={() =>
          setConditionDialogOptions({
            isOpen: false,
            dataAccessRule: undefined,
            permission: undefined,
          })
        }
        onSave={(name, condition, isInclusive) => {
          props.setWizardState((state) => ({
            ...state,
            dataAccessRules: {
              ...Object.entries(state.dataAccessRules || {}).reduce<
                Role['dataAccessRules']
              >((agg, [key, val]) => {
                if (key === conditionDialogOptions.dataAccessRule?.name) {
                  return agg;
                }

                const permissionScopes =
                  conditionDialogOptions.permission
                    ?.conditionalScopesOverrides ||
                  conditionDialogOptions.permission?.scopes ||
                  [];

                const updatedScopes = val.scopes.filter(
                  (x) => !permissionScopes.includes(x)
                );

                agg![key] = {
                  ...val,
                  scopes: updatedScopes,
                };

                return agg;
              }, {}),
              [name]: {
                name,
                condition,
                isInclusive,
                scopes: [
                  ...new Set([
                    ...(conditionDialogOptions.dataAccessRule?.scopes || []),
                    ...(conditionDialogOptions.permission
                      ?.conditionalScopesOverrides ||
                      conditionDialogOptions.permission?.scopes ||
                      []),
                  ]),
                ],
              },
            },
          }));
          setConditionDialogOptions({
            isOpen: false,
            dataAccessRule: undefined,
            permission: undefined,
          });
        }}
      />
    </StepContainer>
  );
};

const clearPermissionFromDataAccessRules = (
  permission: PermissionDescriptor,
  dataAccessRules: Role['dataAccessRules']
) => {
  if (!dataAccessRules) {
    return undefined;
  }

  const conditionalScopes =
    permission.conditionalScopesOverrides || permission.scopes;

  return Object.entries(dataAccessRules).reduce<Role['dataAccessRules']>(
    (agg, [key, val]) => {
      val.scopes = val.scopes.filter((x) => !conditionalScopes.includes(x));
      agg![key] = val;
      return agg;
    },
    {}
  );
};
