import { Box, CardContent, Divider, Stack } from '@mui/material';
import { useEffect } from 'react';

import { RDSSubnetGroupSelect } from '@/components/regions/rdsSubnetGroupSelect';
import { RegionSelect } from '@/components/regions/regionSelect';
import { SecurityGroupSelect } from '@/components/regions/securityGroupSelect';
import { TagsSection } from '@/components/tags/tagsSection';
import type { StepProps } from '@/components/wizard/StepProps';

import { EonTagRemark } from './shared';

import { StepContainer } from '../../wizard/StepContainer';
import { InstanceTypeSelect } from '../instanceTypeSelection';
import type { RestoreEc2InstanceState } from '../restoreEc2InstanceWizard';

export const InstanceConfiguration = (
  props: StepProps<RestoreEc2InstanceState>
) => {
  useEffect(() => {
    props.setWizardState((state) => ({
      ...state,
      encryptionKeyId: undefined,
      securityGroupId: undefined,
      vpc: undefined,
      rdsSubnetGroupName: undefined,
      regionName: state.initialRegionName,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.wizardState.restoreAccountId]);

  return (
    <StepContainer
      navigationComponent={<EonTagRemark />}
      stepperLabels={props.stepperLabels}
      stepperIndex={1}
      nextButtonText='Next'
      onBackClick={props.back}
      canGoNext={() => {
        if (
          !props.wizardState.regionName ||
          !props.wizardState.securityGroupId ||
          !props.wizardState.instanceType ||
          !props.wizardState.rdsSubnetGroupName // todo: put the correct subnet (non-RDS)
        ) {
          return false;
        }

        return true;
      }}
      onNextClick={() => props.setNextStep(props.currentStep.next?.[0])}
    >
      <Box className='mb-[24px]'>
        <Stack
          direction='row'
          alignItems='center'
          gap='36px'
          className='mt-[20px]'
        >
          <RegionSelect
            accountId={props.wizardState.restoreAccountId}
            regionName={props.wizardState.regionName}
            onChange={(region) =>
              props.setWizardState((state) => ({
                ...state,
                regionName: region,
                rdsSubnetGroupName: undefined,
                securityGroupId: undefined,
                vpc: undefined,
                encryptionKeyId: undefined,
              }))
            }
          />
          <RDSSubnetGroupSelect
            accountId={props.wizardState.restoreAccountId}
            regionName={props.wizardState.regionName}
            rdsSubnetGroupName={props.wizardState.rdsSubnetGroupName}
            onChange={(rdsSubnetGroupName, vpc) =>
              props.setWizardState((state) => ({
                ...state,
                rdsSubnetGroupName,
                vpc,
                securityGroupId: undefined,
              }))
            }
          />
        </Stack>
        <Stack
          direction='row'
          alignItems='center'
          gap='36px'
          className='mt-[20px]'
        >
          <InstanceTypeSelect
            accountId={props.wizardState.restoreAccountId}
            regionName={props.wizardState.regionName}
            subnetId={props.wizardState.rdsSubnetGroupName} // todo: put the correct subnet (non-RDS)
            instanceType={props.wizardState.instanceType}
            onChange={(instanceType) =>
              props.setWizardState((state) => ({
                ...state,
                instanceType,
              }))
            }
          />
          <SecurityGroupSelect
            accountId={props.wizardState.restoreAccountId}
            regionName={props.wizardState.regionName}
            securityGroupId={props.wizardState.securityGroupId}
            vpc={props.wizardState.vpc}
            onChange={(securityGroupId) =>
              props.setWizardState((state) => ({
                ...state,
                securityGroupId,
              }))
            }
          />
        </Stack>
      </Box>
      <Divider />
      <CardContent className='px-0'>
        <TagsSection
          initialTags={{}}
          tags={props.wizardState.tags || {}}
          onChange={(tags) =>
            props.setWizardState((state) => ({ ...state, tags }))
          }
        />
      </CardContent>
    </StepContainer>
  );
};
