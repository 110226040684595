import {
  Box,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  List,
  MenuItem,
  Radio,
  Select,
  Switch,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { useDAL } from '@/data/dal';

import { OptionListItem } from '../shared/optionListItem';
import { TransactionalTextField } from '../shared/transactionalTextField';

export const EncryptionComponent = ({
  isEncrypted,
  encryptionKeyId,
  accountId,
  onChange,
  regionName,
}: {
  isEncrypted: boolean;
  encryptionKeyId: string | undefined;
  accountId: string;
  regionName: string;
  onChange: (encryption: Encryption) => void;
}) => {
  const [encryptionParams, setEncryptionParams] = useState<Encryption>({
    isEncrypted,
    encryptionKeyId,
    arn: undefined,
    mode: 'select',
  });
  const dal = useDAL();
  const { isLoading, body } = dal.restore.encryptionKeys.list(
    accountId,
    regionName
  );

  useEffect(() => {
    setEncryptionParams((state) => ({ ...state, encryptionKeyId: undefined }));
  }, [regionName]);

  const isSelectMode = encryptionParams.mode === 'select';
  const isArnMode = encryptionParams.mode === 'arn';

  return (
    <Box className='w-full'>
      <Box className='flex justify-between items-center'>
        <Typography component={'span'} variant='h6'>
          Encrypt this resource
        </Typography>
        <Switch
          className='hidden'
          size='small'
          checked={encryptionParams.isEncrypted}
          onChange={() => {
            const newState = {
              ...encryptionParams,
              isEncrypted: !encryptionParams.isEncrypted,
            };
            setEncryptionParams(newState);
            onChange(newState);
          }}
        />
      </Box>
      {encryptionParams.isEncrypted && (
        <List className='mt-[20px]'>
          <OptionListItem
            isChecked={isSelectMode}
            onClick={() =>
              setEncryptionParams((state) => ({ ...state, mode: 'select' }))
            }
          >
            <FormControlLabel
              checked={isSelectMode}
              onClick={() =>
                setEncryptionParams((state) => ({ ...state, mode: 'select' }))
              }
              control={<Radio />}
              label='Select an existing key'
            />
            <Collapse className='w-full' in={isSelectMode}>
              <Box>
                <FormControl size='small' className='w-full mt-[20px]'>
                  <FormLabel>Encryption Key</FormLabel>
                  <Box className='flex items-center gap-[36px]'>
                    <Box className='w-1/2'>
                      <Select
                        displayEmpty
                        renderValue={(value: string) => {
                          if (!value) {
                            return (
                              <Typography component={'span'}>Select</Typography>
                            );
                          }
                          return value;
                        }}
                        placeholder='Select'
                        disabled={!body || isLoading}
                        size='small'
                        className='w-full'
                        value={encryptionParams.encryptionKeyId}
                        onChange={(event) => {
                          const newState = {
                            ...encryptionParams,
                            encryptionKeyId: event.target.value,
                          };
                          setEncryptionParams(newState);
                          onChange(newState);
                        }}
                      >
                        {body?.keys.map((key) => (
                          <MenuItem key={key.id} value={key.arn}>
                            {key.aliasName}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Box>
                </FormControl>
              </Box>
            </Collapse>
          </OptionListItem>
          <OptionListItem
            isChecked={isArnMode}
            onClick={() =>
              setEncryptionParams((state) => ({ ...state, mode: 'arn' }))
            }
          >
            <FormControlLabel
              checked={isArnMode}
              onClick={() =>
                setEncryptionParams((state) => ({ ...state, mode: 'arn' }))
              }
              control={<Radio />}
              label='Enter a key ARN'
            />
            <Collapse className='w-full' in={isArnMode}>
              <Box>
                <FormControl size='small' className='w-1/2 mt-[24px]'>
                  <FormLabel>ARN</FormLabel>
                  <TransactionalTextField
                    initValue={encryptionParams.arn || ''}
                    onChange={(value) => {
                      const newState = {
                        ...encryptionParams,
                        arn: value,
                      };
                      setEncryptionParams(newState);
                      onChange(newState);
                    }}
                  />
                </FormControl>
              </Box>
            </Collapse>
          </OptionListItem>
        </List>
      )}
    </Box>
  );
};

interface Encryption {
  isEncrypted: boolean;
  encryptionKeyId: string | undefined;
  arn: string | undefined;
  mode: 'arn' | 'select';
}
