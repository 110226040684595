import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import type { InventoryResource } from '@repo/api-gw-sdk';
import { BackupStatus } from '@repo/api-gw-sdk';

import {
  dbEngine,
  resourceId,
  resourceType,
  tags,
} from '@/components/queryBuilder/properties';
import { PropertyGroups } from '@/components/queryBuilder/propertyGroups';
import { groupActionsByLevel } from '@/data/inventory/actions';

import BackupStatusComponent from './backupStatus';
import { Classifications } from './classifications';
import { DatabaseSection } from './databaseSection';
import InstanceActionsBar from './instanceActionsBar';
import { SidePanelPropsTable } from './shared';
import { VolumesSection } from './volumesSection';

import type { InstancePanelProps } from '../../instancePanel';

export const OverviewTab = (props: InstancePanelProps) => {
  const { entity } = props;
  const fields = props.fields
    .map((x) => ({
      id: x.id,
      title: x.title(entity),
      group: x.group,
      value: x.value(entity),
    }))
    .filter((x) => !!x.value);

  // todo: the 'MainPrimary' hack for case where there is no backup status, is for file search record.
  // this would be handled with the nwe UX, that won't open the instance panel anymore.
  // once doing so, we can probably also remove generic T for the entire overviewTab.
  const actions =
    'backupStatus' in entity
      ? groupActionsByLevel(entity.backupStatus, props.actions)
      : { MainPrimary: props.actions };

  return (
    <Box className='pb-[40px]' overflow='auto'>
      <Box className='px-[40px] pt-[40px]'>
        <Typography fontSize={'20px'} data-testid='side-panel-resource-name'>
          {entity.resourceName}
        </Typography>
        <SidePanelPropsTable className='mt-[24px] mb-[24px]'>
          <tbody>
            {[
              fields.find((x) => x.id === resourceType.name)!,
              fields.find((x) => x.id === resourceId.name)!,
              fields.find((x) => x.id === dbEngine.name)!,
              fields.find((x) => x.id === tags.name)!,
            ]
              .filter(Boolean)
              .map((x) => (
                <tr key={x.id}>
                  <td className='props-col'>{x.title}</td>
                  <td>{x.value}</td>
                </tr>
              ))}
          </tbody>
        </SidePanelPropsTable>
      </Box>
      <InstanceActionsBar entity={entity} actions={actions} />

      {'backupStatus' in entity && (
        <BackupStatusComponent
          entity={entity}
          backupStatus={entity.backupStatus}
          onEntityChange={props.onEntityChange}
          resourceId={props.resourceId}
          actions={actions}
        />
      )}
      {'backupStatus' in entity &&
        entity.backupStatus !== BackupStatus.InitialClassification && (
          <Accordion
            disableGutters
            defaultExpanded={props.openSections.Classifications}
          >
            <AccordionSummary data-testid='classifications'>
              {PropertyGroups.classifications.title}
            </AccordionSummary>
            <AccordionDetails>
              <Classifications
                entity={entity as unknown as InventoryResource}
              />
            </AccordionDetails>
          </Accordion>
        )}
      <Accordion
        disableGutters
        defaultExpanded={props.openSections.BackupDetailsFields}
      >
        <AccordionSummary>
          {PropertyGroups['backup-details'].title}
        </AccordionSummary>
        <AccordionDetails>
          <SidePanelPropsTable>
            <tbody>
              {fields
                .filter((x) => x.group === 'backup-details')
                .map((x) => (
                  <tr key={x.id}>
                    <td className='props-col'>{x.title}</td>
                    <td>{x.value}</td>
                  </tr>
                ))}
            </tbody>
          </SidePanelPropsTable>
        </AccordionDetails>
      </Accordion>
      <Accordion
        disableGutters
        defaultExpanded={props.openSections.SourceLocationFields}
      >
        <AccordionSummary>
          {PropertyGroups['source-location'].title}
        </AccordionSummary>
        <AccordionDetails>
          <SidePanelPropsTable>
            <tbody>
              {fields
                .filter((x) => x.group === 'source-location')
                .map((x) => (
                  <tr key={x.id}>
                    <td className='props-col'>{x.title}</td>
                    <td>{x.value}</td>
                  </tr>
                ))}
            </tbody>
          </SidePanelPropsTable>
        </AccordionDetails>
      </Accordion>
      {'volumes' in entity && entity.volumes?.length && (
        <Accordion disableGutters defaultExpanded={false}>
          <AccordionSummary>{'Volumes'}</AccordionSummary>
          <AccordionDetails>
            <VolumesSection resource={entity} />
          </AccordionDetails>
        </Accordion>
      )}
      {'databaseProperties' in entity && entity.databaseProperties && (
        <Accordion disableGutters defaultExpanded={false}>
          <AccordionSummary>{'Database properties'}</AccordionSummary>
          <AccordionDetails>
            <DatabaseSection resource={entity} />
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
};
