import { Box, FormControl, FormLabel, Stack, TextField } from '@mui/material';
import { useState } from 'react';

import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';
import { PreferencesKey } from '@/data/dal/preferences';

import { StepContainer } from '../../wizard/StepContainer';
import { BucketSelection } from '../bucketSelection';
import type { RestoreFileState } from '../restoreFileWizard';

export const FileConfiguration = (props: StepProps<RestoreFileState>) => {
  const dal = useDAL();
  const {
    wizardState: stepState,
    setWizardState: setStepState,
    stepperLabels,
    abort,
    back,
    onSuccess,
  } = props;
  const {
    restoreBucketMode,
    restoreBucketName,
    restoreBucketNameOverride,
    resourceIdForSnapshots,
    snapshotId,
    files,
    restoreAccountId,
    restoreBucketPrefix,
  } = stepState;

  const [error, setError] = useState<string | undefined>();

  const onNextClick = () => {
    setError(undefined);
    const bucketName =
      restoreBucketMode === 'select'
        ? restoreBucketName
        : restoreBucketNameOverride;

    // for saving the bucket name between different restores
    void dal.preferences.updateUserPref(PreferencesKey.RestoreBucket, {
      bucketName,
    });

    void dal.restore
      .files(resourceIdForSnapshots!, snapshotId!, {
        files,
        restoreAccountId: restoreAccountId!,
        restoreBucketPrefix,
        restoreBucketName: bucketName!,
      })
      .then(() => {
        abort();
        onSuccess('Restore job has started!');
      })
      .catch((e) => {
        setError(`Failed to restore file: ${e.message}`);
      });
  };

  return (
    <StepContainer
      stepperLabels={stepperLabels}
      stepperIndex={stepperLabels.length - 1}
      nextButtonText='Start'
      onBackClick={back}
      canGoNext={() => {
        return restoreBucketMode === 'select'
          ? !!restoreBucketName
          : !!restoreBucketNameOverride;
      }}
      navigationComponent={
        error && (
          <Stack alignItems='center' direction='row' sx={{ color: 'red' }}>
            <i className='ri-error-warning-line mr-[8px] text-[18px]' />
            {error}
          </Stack>
        )
      }
      onNextClick={onNextClick}
    >
      <Box>
        <BucketSelection {...props} />
        <FormControl size='small' className='w-full mt-[24px]'>
          <FormLabel>Target Path (optional)</FormLabel>
          <TextField
            size='small'
            value={restoreBucketPrefix || ''}
            onChange={(event) =>
              setStepState((state) => ({
                ...state,
                restoreBucketPrefix: event.target.value,
              }))
            }
          />
        </FormControl>
      </Box>
    </StepContainer>
  );
};
