import {
  Box,
  Button,
  IconButton,
  Radio,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import type { InventoryResource, Snapshot } from '@repo/api-gw-sdk';
import { SnapshotStatus } from '@repo/api-gw-sdk';
import classnames from 'classnames';

import { SnapshotIndicator } from '@/components/snapshots/SnapshotIndicator';
import { useRoles } from '@/contexts/useRoles';
import type { BackupVault } from '@/data/vaults/backupVault';
import { isDateValid } from '@/utils/dateTime';
import { dayjs } from '@/utils/dayjs';

export const SnapshotRow = ({
  snap,
  vault,
  isSelectable,
  isSelected,
  onSelect,
  onRestore,
  onConvertSnapshot,
  volume,
  resource,
}: {
  snap: Snapshot;
  vault: BackupVault | undefined;
  isSelectable: boolean;
  isSelected?: boolean | undefined;
  onSelect?: () => void;
  onRestore: () => void;
  onConvertSnapshot?: () => void;
  volume?: string;
  resource: InventoryResource;
}) => {
  const { isAuthorizedResource } = useRoles();
  const date = isDateValid(snap.pointInTime)
    ? snap.pointInTime
    : snap.createTime;

  return (
    <Stack
      borderTop='1px solid var(--mui-palette-divider)'
      className='py-[24px] px-[40px] w-full'
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      key={snap.id}
    >
      <Stack
        className={classnames('flex-1', { 'cursor-pointer': isSelectable })}
        direction='row'
        alignItems='center'
        gap='16px'
        onClick={() => onSelect?.()}
      >
        {isSelectable && <Radio size='small' checked={isSelected} />}
        {vault &&
          ((snap.status as unknown) === 'PENDING_DISABLED' ? (
            <PendingCircle backgroundColor={vault.backgroundColor} />
          ) : (
            <SnapshotIndicator
              backgroundColor={vault.backgroundColor || null}
            />
          ))}
        {volume && <Typography variant='body1'>{volume}</Typography>}
        <Typography variant='body1'>
          {((snap.status as unknown) === 'PENDING_DISABLED'
            ? 'Started at '
            : '') + dayjs(date).format('HH:mm:ss')}
        </Typography>
      </Stack>
      <Box className='flex justify-end'>
        {(snap.status as unknown) === 'PENDING_DISABLED' && (
          <BackingUpTooltip />
        )}
        {snap.status === SnapshotStatus.Completed && isSelected !== false && (
          <>
            {onConvertSnapshot && (
              <Tooltip title='Convert to EBS Snapshot'>
                <div>
                  <IconButton
                    disabled={
                      !isAuthorizedResource('create:restore_resource', resource)
                    }
                    onClick={onConvertSnapshot}
                    className={'mr-[10px] p-[4px]'}
                  >
                    <i className='material-symbols-repeat-rounded text-[20px] text-primary' />
                  </IconButton>
                </div>
              </Tooltip>
            )}
            <Button
              disabled={
                !isAuthorizedResource('create:restore_resource', resource)
              }
              variant='outlined'
              className='m-0 py-[4px] pr-[12px] pl-[4px]'
              onClick={onRestore}
            >
              <i
                className={`material-symbols-settings-backup-restore-rounded text-[18px] ml-[2px] mr-[8px] text-primary align-middle`}
              />
              Restore
            </Button>
          </>
        )}
      </Box>
    </Stack>
  );
};

export const BackingUpTooltip = () => (
  <Tooltip title='Eon is processing data for this snapshot. Once the backup is complete, restore options will be available.'>
    <Typography
      className='font-semibold px-[12px]'
      color='var(--mui-palette-background-lightGray)'
    >
      Backing up...
    </Typography>
  </Tooltip>
);

export const PendingCircle = ({
  backgroundColor,
}: {
  backgroundColor?: string;
}) => {
  if (!backgroundColor) {
    return null;
  }

  return (
    <Box
      component='span'
      className='p-[2px] rounded-full'
      sx={{ border: `2px solid ${backgroundColor}` }}
    >
      <Box
        component='span'
        className='w-[4px] h-[4px] flex items-center justify-center rounded-full'
        sx={{ backgroundColor: backgroundColor }}
      ></Box>
    </Box>
  );
};
