'use client';

import { Box, Stack } from '@mui/material';
import classNames from 'classnames';
import Link from 'next/link';
import { redirect, usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

import { UserCircle } from '@/components/users/userBadge';
import { useRoles } from '@/contexts/useRoles';
import { useUser } from '@/contexts/useUser';

import { MenuItem, NavigationActions } from './components';
import { UserBox } from './userBox';

import { EONLogo } from '../shared/eonLogo';

export const Navigation = () => {
  const pathname = usePathname();
  const { user } = useUser();
  const [forceOpen, setForceOpen] = useState(false);
  const onboardingMode = user?.eonAccount.status === 'onboarding';
  const registrationMode = user?.eonAccount.status === 'registration';
  const { isAuthorized } = useRoles();

  useEffect(() => {
    if (!user || user.givenName?.toLowerCase().includes('eon support')) return;

    if (onboardingMode && !pathname.includes('/global-settings')) {
      redirect('/global-settings');
    } else if (registrationMode) {
      redirect('/onboarding/inprogress');
    }
  }, [onboardingMode, pathname, registrationMode, user]);

  return (
    <Box
      data-testid='navigation'
      className={classNames({
        open: forceOpen,
      })}
      sx={{
        minWidth: '60px',
        maxWidth: '60px',
        position: 'sticky',
        height: '100%',
        zIndex: 1000,

        '& .slide-left, .logo-link *, .user-badge': {
          transition: `opacity 500ms ease-in-out, padding-left 300ms ease-in-out, margin-left 300ms ease-in-out, visibility 500ms ease-in-out`,
        },

        '& a': {
          display: 'flex',
          alignItems: 'center',
          color: 'white',
          height: '60px',
          position: 'relative',
          width: '100%',
        },

        '&:hover, &.open': {
          '& .user-badge': {
            opacity: 0,
          },

          '& > div': {
            minWidth: '308px',
          },

          '& .slide-left': {
            opacity: '1 !important',
            visibility: 'visible !important',
            paddingLeft: '3px',
          },

          '& .logo-link img': {
            marginLeft: '19px !important',
          },
        },
      }}
    >
      <Stack
        direction='column'
        gap='24px'
        sx={{
          backgroundColor: 'var(--mui-palette-background-dark)',
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          height: '100%',
          minWidth: '60px',
          transition: 'min-width 300ms ease-in-out',
        }}
      >
        <Stack
          direction='column'
          className={classNames(`h-1/3`, {
            'min-h-[280px]':
              (user && !user.selectedRole.isProjectRole) || forceOpen,
            'min-h-[212px]': user?.selectedRole.isProjectRole && !forceOpen,
          })}
        >
          <Link href='/' className='slide-left logo-link'>
            <>
              <EONLogo className='ml-[-16px]' />
              <Box
                className='w-[24px] h-[24px] absolute left-[-4px]'
                sx={{ backgroundColor: 'var(--mui-palette-background-dark)' }}
              />
            </>
          </Link>
          <Box className='relative'>
            <Stack
              className='w-[60px] h-[60px] user-badge opacity-1'
              alignItems='center'
              justifyContent='center'
            >
              <UserCircle name={user?.givenName || user?.email} />
            </Stack>
            <UserBox setForceOpen={setForceOpen} />
          </Box>
        </Stack>
        <Stack direction='column' className='h-1/3 min-h-[360px]'>
          <MenuItem
            key='dashboard'
            href='/dashboard'
            iconClass='material-symbols-team-dashboard-outline'
            title='Dashboard'
            disabled={!isAuthorized('read:dashboard')}
          />
          <MenuItem
            key='inventory'
            data-testid='/inventory'
            href='/inventory'
            iconClass='material-symbols-inventory-2-outline'
            title='Inventory'
            disabled={!isAuthorized('read:inventory')}
          />
          <MenuItem
            key='search'
            href='/search'
            iconClass='material-symbols-search'
            title='Search'
            disabled={!isAuthorized('read:db') && !isAuthorized('read:files')}
          />
          <MenuItem
            key='jobs'
            href='/jobs'
            iconClass='material-symbols-checklist-rounded'
            title='Jobs'
            disabled={!isAuthorized('read:jobs')}
          />
          <MenuItem
            key='audit-logs'
            href='/audit-logs'
            iconClass='material-symbols-content-paste-rounded'
            title='Audit logs'
            disabled={!isAuthorized('read:audit_logs')}
          />
          <MenuItem
            key='settings'
            href='/settings'
            iconClass='material-symbols-settings-outline'
            title='Backup settings'
            disabled={!isAuthorized('read:backup_settings')}
          />
        </Stack>
        <Box className='relative h-1/3 min-h-[36px]'>
          <NavigationActions />
        </Box>
      </Stack>
    </Box>
  );
};
