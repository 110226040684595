import { AccountCloudEnum } from '@repo/api-gw-sdk';

import { useWorkspace } from '@/contexts/useWorkspace';

import { InstanceConfiguration } from './steps/instanceConfiguration';
import { SelectTargetAccount } from './steps/selectTargetAccount';
import { VolumeConfiguration } from './steps/volumeConfiguration';

import { Panels } from '../panels/panels';
import type { Step } from '../wizard/Step';
import { Wizard } from '../wizard/Wizard';

export interface RestoreEc2InstanceState {
  resourceId: string;
  snapshotId: string;
  resourceName?: string;
  restoreAccountCloudProvider?: AccountCloudEnum;
  restoreAccountId?: string;
  encryptionKeyId?: string;
  regionName?: string;
  securityGroupId?: string;
  rdsSubnetGroupName?: string;
  instanceType?: string;
  vpc?: string;
  tags?: Record<string, string>;
  isEncrypted: boolean;
  initialRegionName: string;
}

const restoreEc2InstanceFlow: Step<RestoreEc2InstanceState> = {
  name: 'SelectTargetAccountStep',
  Component: SelectTargetAccount,
  next: [
    {
      name: 'InstanceConfiguration',
      Component: InstanceConfiguration,
      next: [
        {
          name: 'VolumeConfiguration',
          Component: VolumeConfiguration,
        },
      ],
    },
  ],
};

export const RestoreEc2InstanceWizard = ({
  resourceId,
  snapshotId,
  sourceRegion,
}: {
  resourceId: string;
  snapshotId: string;
  sourceRegion?: string;
}) => {
  const { rightPanel } = useWorkspace();
  const { setComponent, setIsOpen } = rightPanel;

  const close = () => {
    setComponent({
      panel: Panels.InventoryInstance,
      props: { id: resourceId, onClose: () => setIsOpen(false) },
    });

    return true;
  };

  return (
    <Wizard<RestoreEc2InstanceState>
      onAbort={(origin) => (origin === 'back' ? close() : setIsOpen(false))}
      onFinish={close}
      flow={[restoreEc2InstanceFlow]}
      initialState={{
        initialRegionName: sourceRegion || 'us-east-1',
        snapshotId,
        resourceId,
        restoreAccountCloudProvider: AccountCloudEnum.Aws,
        isEncrypted: true,
      }}
      title={`Restore EC2 Instance`}
      stepperLabels={[
        'Restore account',
        'Instance configuration',
        'Volume configuration',
      ]}
    />
  );
};
