import { Tooltip } from '@mui/material';
import type { Role } from '@repo/api-gw-sdk';

import type { PermissionDescriptor } from '@/contexts/rolesContext';
import { useFeatureFlags } from '@/contexts/useFeatureFlags';

import { getAssignedDataAccessRule } from './utils';

import { Icon } from '../shared/icon';

export const PermissionIcon = ({
  isPermissionAuthorized,
  editMode,
  permission,
  role,
}: {
  permission: PermissionDescriptor;
  role: Role;
  isPermissionAuthorized: boolean;
  editMode?: boolean;
}) => {
  const { dataAccessRoles } = useFeatureFlags();
  const assignedDataAccessRule = getAssignedDataAccessRule(
    dataAccessRoles,
    permission,
    role
  );

  return (
    <Tooltip title={editMode ? 'Select access level' : undefined}>
      <Icon
        iconClass={
          assignedDataAccessRule
            ? 'material-symbols-rule-rounded'
            : isPermissionAuthorized
              ? 'material-symbols-check'
              : 'material-symbols-close-rounded'
        }
        sx={{
          ...(editMode
            ? {
                cursor: 'pointer',
                backgroundColor: assignedDataAccessRule
                  ? '#FFE8DB'
                  : isPermissionAuthorized
                    ? 'rgba(145,255,210,0.2)'
                    : 'rgba(240,68,101,0.2)',
              }
            : {}),

          i: {
            color: assignedDataAccessRule
              ? '#EC6924'
              : isPermissionAuthorized
                ? 'var(--mui-palette-success-main)'
                : 'var(--mui-palette-error-main)',
          },
        }}
      />
    </Tooltip>
  );
};
