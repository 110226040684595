import { Stack, Typography } from '@mui/material';

import { RoleAccordionBody } from '@/app/(account)/global-settings/shared/scopeAccordion';
import { StepContainer } from '@/components/wizard/StepContainer';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';

import type { UpsertServiceAccountState } from './upsertServiceAccountFlow';

export const ServiceAccountsScopesSelection = (
  props: StepProps<UpsertServiceAccountState>
) => {
  const dal = useDAL();

  return (
    <>
      <StepContainer
        navigationComponent={
          props.error && (
            <Stack alignItems='center' direction='row' sx={{ color: 'red' }}>
              <i className='ri-error-warning-line mr-[8px] text-[18px]' />
              {props.error}
            </Stack>
          )
        }
        hideStepper
        onBackClick={props.back}
        canGoNext={() => !!props.wizardState.roleId}
        nextButtonText={
          props.wizardState.clientId ? 'Save Changes' : 'Create Service Account'
        }
        onNextClick={() => {
          if (props.wizardState.clientId) {
            void dal.serviceAccounts
              .update(props.wizardState.clientId, props.wizardState)
              .then(props.finish);
          } else {
            void dal.serviceAccounts.create(props.wizardState).then((x) => {
              props.setWizardState((state) => ({
                ...state,
                clientId: x.serviceAccount.clientId,
                clientSecret: x.clientSecret,
              }));
              props.setNextStep(
                props.currentStep.next?.find((s) => s.name === 'Token Reveal')
              );
            });
          }
        }}
      >
        <Stack
          className='px-[60px]'
          gap='40px'
          color={'var(--mui-palette-text-primary)'}
        >
          <Typography>{`Choose permissions to give users using this service account:`}</Typography>
          <RoleAccordionBody
            roleToProjectsMappings={
              props.wizardState.roleId
                ? {
                    [props.wizardState.roleId]: {
                      ids: [props.wizardState.projects[0]],
                    },
                  }
                : {}
            }
            onChange={(roleToProjectsMappings) =>
              props.setWizardState((state) => ({
                ...state,
                roleId: Object.keys(roleToProjectsMappings)[0],
              }))
            }
            projectId={props.wizardState.projects[0]}
            singleSelect
          />
        </Stack>
      </StepContainer>
    </>
  );
};
