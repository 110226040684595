import { CardContent, Divider } from '@mui/material';
import { useEffect } from 'react';

import { EncryptionComponent } from '@/components/encryption/encryptionComponent';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';

import { EonTagRemark } from './shared';

import { StepContainer } from '../../wizard/StepContainer';
import type { RestoreEc2InstanceState } from '../restoreEc2InstanceWizard';

export const VolumeConfiguration = (
  props: StepProps<RestoreEc2InstanceState>
) => {
  const dal = useDAL();

  useEffect(() => {
    props.setWizardState((state) => ({
      ...state,
      encryptionKeyId: undefined,
      securityGroupId: undefined,
      vpc: undefined,
      rdsSubnetGroupName: undefined,
      regionName: state.initialRegionName,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.wizardState.restoreAccountId]);

  return (
    <StepContainer
      navigationComponent={<EonTagRemark />}
      stepperLabels={props.stepperLabels}
      stepperIndex={2}
      nextButtonText='Start'
      onBackClick={props.back}
      canGoNext={() => {
        if (
          props.wizardState.isEncrypted &&
          !props.wizardState.encryptionKeyId
        ) {
          return false;
        }

        return true;
      }}
      // todo: implement when API is ready
      onNextClick={() =>
        dal.restore.ec2.restore(
          props.wizardState.resourceId,
          props.wizardState.snapshotId,
          {}
        )
      }
    >
      <CardContent className='px-0'>
        {props.wizardState.regionName && (
          <EncryptionComponent
            isEncrypted={props.wizardState.isEncrypted}
            encryptionKeyId={props.wizardState.encryptionKeyId}
            accountId={props.wizardState.restoreAccountId!}
            regionName={props.wizardState.regionName}
            onChange={(encryption) => {
              if (encryption.isEncrypted) {
                props.setWizardState((state) => ({
                  ...state,
                  isEncrypted: true,
                  encryptionKeyId:
                    encryption.mode === 'arn'
                      ? encryption.arn
                      : encryption.encryptionKeyId,
                }));
              } else {
                props.setWizardState((state) => ({
                  ...state,
                  encryptionKeyId: undefined,
                  isEncrypted: false,
                }));
              }
            }}
          />
        )}
      </CardContent>
      <Divider />
      <CardContent className='px-0'>VOLUMES DETAILS GOES HERE</CardContent>
    </StepContainer>
  );
};
