import { FormControl, FormLabel, MenuItem, Select } from '@mui/material';

import { useDAL } from '@/data/dal';

import { EmptySelect } from '../regions/emptySelect';

export const InstanceTypeSelect = ({
  accountId,
  regionName,
  instanceType,
  onChange,
  subnetId,
}: {
  accountId?: string;
  regionName?: string;
  instanceType?: string;
  subnetId?: string;
  onChange: (value: string) => void;
}) => {
  if (!accountId || !regionName || !subnetId) {
    return <EmptySelect title='Instance type' />;
  }

  return (
    <InnerInstanceTypeSelect
      subnetId={subnetId}
      accountId={accountId}
      regionName={regionName}
      instanceType={instanceType}
      onChange={onChange}
    />
  );
};

const InnerInstanceTypeSelect = ({
  subnetId,
  accountId,
  regionName,
  instanceType,
  onChange,
}: {
  accountId: string;
  regionName: string;
  subnetId: string;
  instanceType?: string;
  onChange: (value: string) => void;
}) => {
  const dal = useDAL();
  const { body } = dal.restore.instanceTypes.list(
    accountId,
    regionName,
    subnetId
  );

  if (!body?.types.length) {
    return <EmptySelect title='Instance type' />;
  }

  return (
    <FormControl size='small' className='flex-1'>
      <FormLabel>Instance type</FormLabel>
      <Select
        size='small'
        value={instanceType || ''}
        onChange={(event) => onChange(event.target.value)}
      >
        {body.types.map((instanceType) => (
          <MenuItem key={instanceType} value={instanceType}>
            {instanceType}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
