import { Checkbox, Divider, Radio, Stack, Typography } from '@mui/material';
import type { Project, Role, User } from '@repo/api-gw-sdk';
import { useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@/components/accordion';
import { RolePermissions } from '@/components/permissions/rolePermissions';
import { RoleTag } from '@/components/permissions/rolesTag';
import { rolesComparer } from '@/configs/roles';
import { useRoles } from '@/contexts/useRoles';

export const ScopeAccordion = ({
  roleToProjectsMappings,
  onChange,
  project,
}: {
  project?: Project;
  roleToProjectsMappings: User['roleToProjectsMappings'];
  onChange: (roleToProjectsMappings: User['roleToProjectsMappings']) => void;
}) => {
  const { rolesMap } = useRoles();
  const assignedRoles = Object.keys(roleToProjectsMappings || {})
    .reduce<Role[]>((agg, roleId) => {
      const role = rolesMap[roleId];
      if (role && !project && !role.isProjectRole) {
        agg.push(role);
      }

      if (
        role &&
        project &&
        role.isProjectRole &&
        roleToProjectsMappings[roleId]?.ids?.includes(project.id)
      ) {
        agg.push(role);
      }

      return agg;
    }, [])
    .sort(rolesComparer);

  return (
    <Accordion>
      <AccordionSummary
        className='px-[40px]'
        sx={{
          '.Mui-expanded': {
            '.role-tags': {
              display: 'none',
            },
          },
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          gap='60px'
          className='w-full'
        >
          <Typography className='font-semibold'>
            {project?.name ?? 'Global Settings'}
          </Typography>
          <Stack
            className='role-tags'
            direction='row'
            alignItems='center'
            justifyContent='center'
            gap='8px'
          >
            {assignedRoles.map((role) => (
              <RoleTag key={role.id} role={role} />
            ))}
          </Stack>
          {!assignedRoles.length && (
            <Typography className='font-normal role-tags'>
              No permissions
            </Typography>
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails className='px-0 pt-[28px]'>
        <RoleAccordionBody
          projectId={project?.id}
          onChange={onChange}
          roleToProjectsMappings={roleToProjectsMappings}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export const RoleAccordionBody = ({
  roleToProjectsMappings,
  onChange,
  projectId,
  singleSelect,
}: {
  projectId?: string;
  roleToProjectsMappings: User['roleToProjectsMappings'];
  onChange: (roleToProjectsMappings: User['roleToProjectsMappings']) => void;
  singleSelect?: boolean;
}) => {
  const { roles } = useRoles();
  const relevantRoles = roles
    .filter((role) => (projectId ? role.isProjectRole : !role.isProjectRole))
    .sort(rolesComparer);

  const [selectedRole, setSelectedRole] = useState<Role>(
    relevantRoles.find((role) =>
      projectId
        ? !!roleToProjectsMappings[role.id]?.ids?.includes(projectId)
        : !!roleToProjectsMappings[role.id]
    ) || relevantRoles[0]
  );

  return (
    <Stack direction='row' className='pr-[40px]'>
      <Stack className='w-[260px] min-w-[260px]'>
        <Typography className='mb-[36px] pl-[60px]'>Assigned roles</Typography>
        <Typography
          variant='body2'
          sx={{
            fontSize: '12px',
            textTransform: 'uppercase',
            cursor: 'default',
            margin: '12px 0 12px 60px',
          }}
        >
          EON roles
        </Typography>
        {relevantRoles
          .filter((role) => role.isBuiltInRole)
          .map((role) => (
            <RoleRow
              key={role.id}
              role={role}
              projectId={projectId}
              onChange={onChange}
              roleToProjectsMappings={roleToProjectsMappings}
              onClick={() => setSelectedRole(role)}
              isSelected={selectedRole.id === role.id}
              singleSelect={!!singleSelect}
            />
          ))}
        {relevantRoles.some((role) => !role.isBuiltInRole) && (
          <>
            <Divider className='my-[12px]' />
            <Typography
              variant='body2'
              sx={{
                fontSize: '12px',
                textTransform: 'uppercase',
                cursor: 'default',
                margin: '12px 0 12px 60px',
              }}
            >
              Custom roles
            </Typography>
          </>
        )}
        {relevantRoles
          .filter((role) => !role.isBuiltInRole)
          .map((role) => (
            <RoleRow
              key={role.id}
              role={role}
              projectId={projectId}
              onChange={onChange}
              roleToProjectsMappings={roleToProjectsMappings}
              onClick={() => setSelectedRole(role)}
              isSelected={selectedRole.id === role.id}
              singleSelect={!!singleSelect}
            />
          ))}
      </Stack>

      <Divider orientation='vertical' flexItem className='mr-[40px]' />
      <Stack gap='4px' flexGrow={1}>
        <Typography className='mb-[32px]'>
          {`The following permissions will be available for `}
          <b>{selectedRole.name}</b>
          {':'}
        </Typography>
        <RolePermissions role={selectedRole} />
      </Stack>
    </Stack>
  );
};

const RoleRow = ({
  role,
  projectId,
  roleToProjectsMappings,
  onChange,
  onClick,
  isSelected,
  singleSelect,
}: {
  role: Role;
  projectId?: string;
  roleToProjectsMappings: User['roleToProjectsMappings'];
  onChange: (roleToProjectsMappings: User['roleToProjectsMappings']) => void;
  onClick: () => void;
  isSelected: boolean;
  singleSelect: boolean;
}) => {
  return (
    <Stack
      direction='row'
      alignItems='center'
      onClick={onClick}
      className='pl-[60px] py-[8px] cursor-pointer'
      sx={(theme) => ({
        borderRightStyle: 'solid',
        borderRightWidth: '3px',
        borderRightColor: isSelected
          ? 'var(--mui-palette-primary-main)'
          : 'transparent',

        '&:hover': {
          backgroundColor:
            theme.palette.mode === 'dark' ? '#1A2027' : '#E7F0F3',
        },
      })}
    >
      {!singleSelect && (
        <Checkbox
          checked={
            projectId
              ? !!roleToProjectsMappings[role.id]?.ids?.includes(projectId)
              : !!roleToProjectsMappings[role.id]
          }
          onChange={(event, checked) => {
            if (projectId) {
              roleToProjectsMappings[role.id] =
                roleToProjectsMappings[role.id] || {};
              roleToProjectsMappings[role.id].ids =
                roleToProjectsMappings[role.id].ids || [];
            }

            if (checked) {
              if (projectId) {
                roleToProjectsMappings[role.id] = {
                  ids: [...roleToProjectsMappings[role.id].ids!, projectId],
                };
              } else {
                roleToProjectsMappings[role.id] = {};
              }
            } else {
              if (projectId) {
                roleToProjectsMappings[role.id] = {
                  ids: roleToProjectsMappings[role.id].ids!.filter(
                    (id) => id !== projectId
                  ),
                };
              }

              if (
                !projectId ||
                roleToProjectsMappings[role.id].ids!.length === 0
              ) {
                // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                delete roleToProjectsMappings[role.id];
              }
            }

            onChange(roleToProjectsMappings);
          }}
        />
      )}
      {singleSelect && (
        <Radio
          checked={
            projectId
              ? !!roleToProjectsMappings[role.id]?.ids?.includes(projectId)
              : !!roleToProjectsMappings[role.id]
          }
          onChange={() => {
            const newMapping = {
              [role.id]: projectId ? { ids: [projectId] } : {},
            };

            onChange(newMapping);
          }}
        />
      )}

      <Typography className='ml-[12px]'>{role.name}</Typography>
    </Stack>
  );
};
