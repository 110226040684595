import {
  Divider,
  FormControl,
  FormLabel,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { Fragment, useState } from 'react';

import {
  RoleAccordionBody,
  ScopeAccordion,
} from '@/app/(account)/global-settings/shared/scopeAccordion';
import { StepContainer } from '@/components/wizard/StepContainer';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';

import type { UpsertUserState } from './upsertUserFlow';
import { UserBadge } from './userBadge';

export const UpsertUserStep = (props: StepProps<UpsertUserState>) => {
  const dal = useDAL();
  const { body: projectsBody } = dal.projects.list();
  const [emailError, setEmailError] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <>
      <StepContainer
        hideStepper
        onBackClick={props.back}
        canGoNext={() =>
          !emailError &&
          !!props.wizardState.email &&
          !!props.wizardState.givenName &&
          !!Object.keys(props.wizardState.roleToProjectsMappings).length
        }
        nextButtonText={props.wizardState.id ? 'Save Changes' : 'Invite'}
        backButtonText='Cancel'
        onNextClick={props.finish}
      >
        <Stack gap='12px'>
          {props.wizardState.id && (
            <UserBadge
              user={props.wizardState}
              className='mx-[60px] pb-[12px]'
            />
          )}
          {!props.wizardState.id && (
            <>
              <Typography className='mx-[60px] font-semibold'>
                Personal details
              </Typography>
              <Stack
                gap='16px'
                className='px-[60px] py-[12px] w-1/2'
                direction='row'
              >
                <FormControl size='small' className='w-1/2'>
                  <FormLabel>Name</FormLabel>
                  <TextField
                    value={props.wizardState.givenName}
                    onChange={(event) =>
                      props.setWizardState((state) => ({
                        ...state,
                        givenName: event.target.value,
                      }))
                    }
                  />
                </FormControl>
                <FormControl size='small' className='w-1/2'>
                  <FormLabel>Email</FormLabel>
                  <TextField
                    error={emailError}
                    helperText={emailError ? 'Invalid email' : ''}
                    onChange={(event) => {
                      props.setWizardState((state) => ({
                        ...state,
                        email: event.target.value,
                      }));
                      setEmailError(!event.target.validity.valid);
                    }}
                    inputProps={{
                      type: 'email',
                    }}
                    value={props.wizardState.email}
                  />
                </FormControl>
              </Stack>
            </>
          )}
          {!props.wizardState.id && (
            <>
              <Divider sx={{ mx: '-40px', my: '12px' }} />
              <Typography className='my-[12px] mx-[60px] font-semibold'>
                Permissions
              </Typography>
            </>
          )}
          <Tabs
            onChange={(e, i) => setSelectedIndex(i)}
            value={selectedIndex}
            className='px-[100px]'
            sx={{
              marginX: '-40px',

              '.MuiTabs-scroller': { display: 'flex' },
            }}
          >
            <Tab
              label='Global settings'
              className='text-[14px] font-normal'
              disableRipple
            />
            <Tab
              label='Projects'
              className='text-[14px] font-normal'
              disableRipple
            />
          </Tabs>
          {selectedIndex === 0 && (
            <Stack gap='12px'>
              <Typography className='m-[24px_60px_24px_60px]'>
                Edit the permissions this user has on global settings
              </Typography>
              <RoleAccordionBody
                roleToProjectsMappings={
                  props.wizardState.roleToProjectsMappings
                }
                onChange={(roleToProjectsMappings) =>
                  props.setWizardState((state) => ({
                    ...state,
                    roleToProjectsMappings: { ...roleToProjectsMappings },
                  }))
                }
              />
            </Stack>
          )}
          {selectedIndex === 1 && (
            <Stack gap='12px'>
              <Typography className='m-[24px_60px_12px_60px]'>
                Edit the permissions this user has on different projects
              </Typography>
              {projectsBody?.projects.map((project, index, projects) => (
                <Fragment key={project.id}>
                  <ScopeAccordion
                    project={project}
                    roleToProjectsMappings={
                      props.wizardState.roleToProjectsMappings
                    }
                    onChange={(roleToProjectsMappings) =>
                      props.setWizardState((state) => ({
                        ...state,
                        roleToProjectsMappings: { ...roleToProjectsMappings },
                      }))
                    }
                  />
                  {index < projects.length - 1 && (
                    <Divider sx={{ mx: '-40px' }} />
                  )}
                </Fragment>
              ))}
            </Stack>
          )}
        </Stack>
      </StepContainer>
    </>
  );
};
